import React from 'react'
import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import { getCorporateGroupAddNew } from '../../constants/addNewButton/corporateGroup'
import dictionary from '../../dictionary'
import { useStore } from '../../store'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'name',
    key: 'name',
    name: dictionary().NAME,
    type: 'text',
  },
  {
    id: 'date_created',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'time_created',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
]

const CorporateGroups: React.FC = () => {
  const userData = window.localStorage.getItem('activeRole')
  const { role } = useStore()

  return (
    <ViewAll
      headers={headers}
      title={dictionary().CORPORATES}
      to='/corporate-groups/new'
      tags={[{ name: dictionary().ALL, filter: [{ name: '', value: '' }] }]}
      hasSearch={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['toll-station-prices']}
      countArrayPath={['count']}
      restRoute={'/corporate-group'}
      cashingKey={CASHING_KEY.GET_CORPORATE_GROUPS}
      addNew={getCorporateGroupAddNew(role ?? '')}
      searchPlaceholder={dictionary().SEARCH_WITH_NAME_OR_ID}
    />
  )
}

export default CorporateGroups
