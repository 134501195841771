import React from 'react'
import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'
import { useStore } from '../../store'

const Admins: React.FC = () => {
  const headers = [
    {
      id: 'id',
      key: 'id',
      name: dictionary().ID,
      type: 'text',
    },
    {
      id: 'name',
      key: 'name.ar',
      name: dictionary().NAME,
      type: 'text',
    },
    {
      id: 'email',
      key: 'email',
      name: dictionary().EMAIL,
      type: 'text',
    },
    {
      id: 'username',
      key: 'username',
      name: dictionary().USERNAME,
      type: 'text',
    },
    {
      id: 'role',
      key: 'role',
      name: dictionary().ROLE,
      type: 'text',
    },
    {
      id: 'active',
      key: 'active',
      name: dictionary().IS_ACTIVE,
      type: 'boolean',
    },
  ]

  const { role } = useStore()

  role === 'SUPER' &&
    headers.push({
      id: 'forceLogout',
      key: 'forceLogout',
      name: dictionary().ACTIONS,
      type: 'modal',
      // payload: { name: 'تغيير' },
    })

  return (
    <ViewAll
      headers={headers}
      title={dictionary().ADMINS}
      to='/admins/new'
      tags={[{ name: dictionary().ALL, filter: [{ name: '', value: '' }] }]}
      hasSearch={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['admins']}
      countArrayPath={['count']}
      restRoute={'/admin'}
      cashingKey={CASHING_KEY.GET_ADMINS}
      enableIdRedirect={true}
      searchPlaceholder={dictionary().SEARCH_WITH_NAME_OR_ID}
    />
  )
}

export default Admins
