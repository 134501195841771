import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import dictionary from '../../dictionary'
import Reqiured from './reqiured'

interface Props {
  register: any
  getValues?: any
  setValue?: any
  width?: any
  minHight?: any
  errors?: any
  error?: any
  pinLength: number
}

const PinCodeInput: React.FC<Props> = ({ register, errors, setValue, width, minHight, error, pinLength }) => {
  const generatePinCode = () => `${Math.floor(1000 + Math.random() * 9000)}`
  const [pinCode, setPinCode] = useState<any>(null)

  // useEffect(() => {
  //   setPinCode(generatePinCode())
  //   setValue('pin_code', generatePinCode())
  // }, [])

  return (
    <FormControl id={dictionary().PIN} w={width} minH={minHight} display='block' isInvalid={!!errors?.pin_code}>
      <FormLabel mb='10px'>
        {dictionary().PIN} <Reqiured />
      </FormLabel>
      <Input
        type='number'
        data-test={dictionary().PIN}
        disabled={pinLength === null}
        {...register('pin_code', {
          required: dictionary().REQUIRED,
          validate: (value: any) => {
            return value.length === pinLength ? true : dictionary().PIN_CODE_WRONG_LENGTH + pinLength
          },
        })}
      />
      <FormErrorMessage>{errors?.pin_code?.message}</FormErrorMessage>
      <FormErrorMessage>{error?.data?.message?.length > 0 && typeof error?.data?.message !== 'string' && error?.data?.message[0]}</FormErrorMessage>
      <FormErrorMessage>{error?.status === 404 && error?.data?.message}</FormErrorMessage>
    </FormControl>
  )
}

export default PinCodeInput
