import { Flex, FormControl, FormErrorMessage, FormLabel, Input, useToast } from '@chakra-ui/react'
import React, { useRef } from 'react'

import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import Card from '../../components/core/Card'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Reqiured from '../../components/core/reqiured'
import SaveButton from '../../components/core/SaveButton'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'

const AddVehicleBrand: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)
  const toast = useToast()
  const { push } = useHistory()
  const { onSuccess } = useAlert()

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', '/vehicle-brand', { data: vars }), {
    onSuccess: (): void => {
      push('/vehicle-brands')
      onSuccess()
    },

    onError: (error: any) => {
      if (error?.data?.message === 'This vehicle brand already exists.') {
        error.data.message = dictionary().BRAND_NAME_ALREADY_EXISTS
      }
      toast({
        containerStyle: {
          direction: 'rtl',
        },
        title: dictionary().ERROR,
        description: error?.data?.message,
        status: 'error',
        duration: 3000,
        position: 'top',
      })
    },
  })

  const addVehicleBrand = async (formData: any) => {
    mutate(formData)
  }

  return (
    <Container h={'100vh'}>
      <Flex ref={headerRef} flexDir='column' className='margin-150'>
        <Header
          title={dictionary().ADD_NEW_VEHICLE}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && addVehicleBrand(getValues() as any)} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(addVehicleBrand as any)}>
          <Flex className='margin-100' justify='center' align='center' direction='column'>
            <FormControl id={dictionary().BRAND_NAME} w={['100%', '70%', '70%', '70%']} display='block' isInvalid={!!errors?.brand_name}>
              <FormLabel mb='10px'>
                {dictionary().BRAND_NAME} <Reqiured />{' '}
              </FormLabel>
              <Input
                data-test={dictionary().BRAND_NAME}
                type='text'
                {...register('brand_name', {
                  required: dictionary().ADD_VALID_BRAND_NAME,
                  pattern: {
                    value: /^[a-zA-Z0-9\u0600-\u06FF]+[a-zA-Z0-9\u0600-\u06FF ]*$/,
                    message: dictionary().ADD_VALID_BRAND_NAME,
                  },
                })}
              />
              <FormErrorMessage>{errors?.brand_name?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddVehicleBrand
