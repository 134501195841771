import React from 'react'

import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'name',
    key: 'name',
    name: dictionary().VEHICLE_MODEL,
    type: 'text',
  },
  {
    id: 'vehicleBrand.brand_name',
    key: 'vehicleBrand.brand_name',
    name: dictionary().VEHICLE_BRAND_NAME,
    type: 'text',
  },
  {
    id: 'vehicleType.name.ar',
    key: 'vehicleType.name.ar',
    name: dictionary().VEHICLE_TYPE,
    type: 'text',
  },
  {
    id: 'vehicleModelAction',
    key: 'vehicleModelAction',
    name: '',
    type: 'modal',
  },
]

const VehicleModels: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().VEHICLE_MODELS}
      to='/vehicle-models/new'
      tags={[{ name: dictionary().ALL, filter: [{ name: '', value: '' }] }]}
      hasSearch={false}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['vehicle-models']}
      countArrayPath={['count']}
      restRoute={'/vehicle-model'}
      cashingKey={CASHING_KEY.GET_VEHICLE_MODELS}
      enableIdRedirect={false}
    />
  )
}

export default VehicleModels
