import React from 'react'

import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },

  {
    id: 'driver_name',
    key: 'name',
    name: dictionary().DRIVER_NAME,
    type: 'text',
  },
  {
    id: 'mobile_number',
    key: 'mobile_number',
    name: dictionary().DRIVER_PHONE,
    type: 'text',
  },
  {
    id: 'username',
    key: 'username',
    name: dictionary().DRIVER_USER_NAME,
    type: 'text',
  },
  {
    id: 'driver_corporate',
    key: 'corporate.name',
    name: dictionary().CORPORATE,
    type: 'text',
  },
  {
    id: 'drivers_date_created',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'drivers_time_created',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'fuel_balance',
    key: 'ledger_accounts',
    name: dictionary().AVIALABLE_BALANCE,
    type: 'Fuel_Balance',
    isRight: true,
  },
  {
    id: 'is_demo',
    key: 'is_demo',
    name: dictionary().TYPE,
    type: 'boolean',
  },
]

const Drivers: React.FC = () => {
  return (
    <>
      <ViewAll
        headers={headers}
        title={dictionary().DRIVERS}
        to='/drivers/new'
        // tags={[
        //   { name: dictionary().ALL, filter: [{ name: '', value: '' }] },
        //   { name: dictionary().IS_ACTIVE, filter: [{ name: 'is_active', value: true }] },
        //   { name: dictionary().NOT_ACTIVE, filter: [{ name: 'is_active', value: false }] },
        // ]}
        hasSearch={true}
        sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
        dataArrayPath={['drivers']}
        countArrayPath={['count']}
        restRoute={'/drivers'}
        cashingKey={CASHING_KEY.GET_DRIVERS}
        hasStationDropListFilter={false}
        searchPlaceholder={dictionary().SEARCH_WITH_NAME_OR_ID}
        corporateDropListPlaceholder={dictionary().CHOOSE_CORPORATE_PLACEHOLDER}
      />
    </>
  )
}

export default Drivers
