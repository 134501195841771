import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { parse, stringify } from 'query-string'
import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import Reqiured from '../../components/core/reqiured'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'

interface Props {
  data: any
  handleIsBalancesChange?: () => void
}

const EditVehicleBrand: React.FC<Props> = ({ data, handleIsBalancesChange }) => {
  const { onSuccess } = useAlert()
  const { push } = useHistory()
  const query = parse(location.search, { parseNumbers: true, parseBooleans: true })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('PATCH', `/vehicle-brand`, { data: vars }), {
    onSuccess: (): void => {
      handleIsBalancesChange && handleIsBalancesChange()
      onSuccess()
      onClose()
      push({ search: stringify(query) })
    },
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useForm({ mode: 'onChange' })

  const Update = async () => {
    const values: { [key: string]: any } = {
      id: data?.id,
      brand_name: getValues().brand_name.trim(),
    }
    mutate(values)
  }

  const handleOpen = () => {
    setValue('brand_name', data?.brand_name)
    errors?.brand_name && delete errors?.brand_name
    onOpen()
  }

  const finalRef = useRef(null)

  return (
    <>
      <img
        ref={finalRef}
        data-test={dictionary().EDIT}
        style={{ cursor: 'pointer', marginRight: 'auto', marginLeft: 'auto' }}
        onClick={handleOpen}
        src={'/assets/edit-purple.svg'}
        alt='edit'
        title={dictionary().EDIT}
      />

      <Modal finalFocusRef={finalRef} isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent sx={{ minWidth: '600px' }}>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().EDIT_BRAND} </ModalHeader>
          <ModalBody pb={6}>
            <form dir='rtl' onSubmit={handleSubmit(Update as any)}>
              <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
                <FormControl
                  id={dictionary().BRAND_NAME}
                  w='90%'
                  minH='90px'
                  display='block'
                  isInvalid={!!errors?.brand_name || (error as any)?.data?.message === 'This vehicle brand already exists.'}
                >
                  <FormLabel mb='10px'>
                    {dictionary().BRAND_NAME} <Reqiured />{' '}
                  </FormLabel>
                  <Input
                    data-test={dictionary().BRAND_NAME}
                    type='text'
                    {...register('brand_name', {
                      required: dictionary().ADD_VALID_BRAND_NAME,
                      pattern: {
                        value: /^[a-zA-Z0-9\u0600-\u06FF]+[a-zA-Z0-9\u0600-\u06FF ]*$/,
                        message: dictionary().ADD_VALID_BRAND_NAME,
                      },
                    })}
                  />
                  <FormErrorMessage>{errors?.brand_name?.message}</FormErrorMessage>
                  <FormErrorMessage>
                    {(error as any)?.data?.message === 'This vehicle brand already exists.' && dictionary().BRAND_NAME_ALREADY_EXISTS}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
            </form>
          </ModalBody>

          <ModalFooter sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Button data-test={dictionary().CANCEL} verticalAlign={'center'} onClick={onClose} mr={3}>
              <Text mt={-1}>{dictionary().CANCEL}</Text>
            </Button>
            <Button
              verticalAlign={'center'}
              data-test={dictionary().SAVE}
              onClick={async () => (await trigger()) && Update()}
              colorScheme='blue'
              disabled={isLoading}
              mr={3}
            >
              <Text mt={-1}>{isLoading ? <Spinner /> : dictionary().SAVE}</Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default EditVehicleBrand
