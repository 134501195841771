import React, { useEffect, useRef } from 'react'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { useFilterStore } from '../../stores/filterStore'
import dictionary from '../../dictionary'
import AutoCompleteInput from './AutoCompleteInput'

interface Props {
  setSelectedCorporate: (val: any) => void
  corporateDropListPlaceholder: string
}

const CorporateDropList: React.FC<Props> = ({ setSelectedCorporate, corporateDropListPlaceholder }) => {
  const { pathname } = useLocation()
  const { corporateId: selectedCorporate, setCorporateId, advancedSearch, corporateIds } = useFilterStore()

  const {
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
  } = useForm({ mode: 'onChange' })

  const corporate = useRef({})
  corporate.current = watch('corporate', '')
  const queryParams = new URLSearchParams(window.location.search)
  const corporateId = queryParams.get('corporateId')

  useEffect(() => {
    if (typeof corporate.current !== null && corporate.current !== undefined && corporate.current !== '') {
      if (pathname === '/fuel-transactions') {
        setCorporateId(getValues()?.corporate[0] === undefined ? null : getValues()?.corporate[0])
      } else {
        setSelectedCorporate(getValues()?.corporate[0])
      }
    }
  }, [corporate.current])

  useEffect(() => {
    if (pathname === '/fuel-transactions') {
      selectedCorporate && setValue('corporate', [selectedCorporate])
    } else {
      corporateId && setValue('corporate', [corporateId])
    }
  }, [])

  return pathname === '/fuel-transactions' && corporateIds.length > 0 ? (
    <></>
  ) : (
    <FormControl id={dictionary().CHOOSE_CORPORATE} w='100%' dir='rtl' isDisabled={corporateIds.length > 0}>
      <FormLabel>{dictionary().CHOOSE_CORPORATE}</FormLabel>
      <Controller
        name={`corporate`}
        rules={{ required: true }}
        control={control}
        render={({ field: { onChange, value } }) => (
          <AutoCompleteInput
            isInvalid={!!errors?.second_ref_id}
            onChange={onChange}
            value={pathname === '/fuel-transactions' ? (corporateIds.length > 0 ? [] : []) : value}
            searchRoute='/corporates'
            singleRoute='/corporates'
            valueFormatter={(option) => option?.id}
            displayFormatter={(option) => option?.name}
            // multiple={pathname === '/fuel-transactions' ? true : false}
            // boxProps={pathname === '/fuel-transactions' ? { maxWidth: '400px', maxHeight: '60px', overflowY: 'scroll' } : {}}
            placeholder={corporateDropListPlaceholder}
            dataTest={dictionary().CHOOSE_CORPORATE_PLACEHOLDER}
          />
        )}
      />
    </FormControl>
  )
}

export default CorporateDropList
