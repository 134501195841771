import React from 'react'
import ViewOne from '../../components/layout/ViewOne'
import dictionary from '../../dictionary'
import { CASHING_KEY } from '../../constants'

const header = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'brand_name',
    key: 'brand_name',
    name: dictionary().BRAND_NAME,
    type: 'text',
  },
]
const actions = [
  {
    action: 'edit',
    title: dictionary().EDIT,
  },
]

const VehicleBrand: React.FC = () => {
  return (
    <ViewOne
      actions={actions}
      header={header}
      restRoute='/vehicles/brands'
      dataLocation={''}
      title={dictionary().VEHICLE_BRAND}
      cashingKey={CASHING_KEY.GET_SINGLE_VEHICLE_BRAND}
      userType='vehicle-brand'
    />
  )
}

export default VehicleBrand
