import { Box, Flex, GridItem, Stack, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import Card from './Card'
import Table from './Table'

const vehicleNFCHeaders = [
  {
    id: 'id',
    key: 'id',
    name: 'معرف الرقم التسلسلي لـ NFC',
    type: 'text',
  },

  {
    id: 'nfc_serial_number',
    key: 'nfc_serial_number',
    name: 'الرقم التسلسلي لـ NFC',
    type: 'text',
  },
  {
    id: 'assigned_date',
    key: 'assigned_date',
    name: 'تاريخ التعيين',
    type: 'date',
  },
]

const VehicleNFC = () => {
  const { id } = useParams<{ id: string }>()
  const [nfcData, setNfcData] = useState([])
  const { mutate, isLoading } = useMutation(() => fetch('GET', `/vehicles/${id}/nfc-logs`), {
    onSuccess: (data) => {
      setNfcData(data)
    },
  })
  useEffect(() => {
    mutate()
  }, [])

  return (
    <Card h='100%' p={3} data-test='nfc-logs'>
      <Text m={3} fontSize='20px' fontWeight={700} p={[0, 2, 2, 2]}>
        {dictionary().NFC_LOGS}
      </Text>
      <hr />
      <GridItem m='2' colSpan={8} mt={7}>
        <Box w='100%'>
          <Stack>
            <GridItem>
              {nfcData && nfcData?.length > 0 ? (
                <Table raised enableIdRedirect={true} height={300} headers={vehicleNFCHeaders} data={nfcData} />
              ) : !isLoading ? (
                <Flex w='100%' h='100%' align='center' justify='center'>
                  <Text fontSize='2xl'>
                    {dictionary().NO_RESULTS_FOUND} {dictionary().IN} {dictionary().NFC_LOGS}
                  </Text>
                </Flex>
              ) : (
                <Flex w='100%' h='100%' align='center' justify='center'>
                  <Text fontSize='2xl'>
                    {dictionary().SEARCH_LOADING} {dictionary().IN} {dictionary().NFC_LOGS}
                  </Text>
                </Flex>
              )}
            </GridItem>
          </Stack>
        </Box>
      </GridItem>
    </Card>
  )
}

export default VehicleNFC
