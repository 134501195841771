import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import React, { useState } from 'react'
import dictionary from '../../dictionary'
import Reqiured from './reqiured'

interface Props {
  register: any
  errors: any
  watch: any
  width: any
  minHight?: any
  error?: any
  isPasswordRequired?: boolean
}

const PasswordAndConfirmation: React.FC<Props> = ({ register, errors, watch, width, minHight, error, isPasswordRequired = true }) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickPassword = () => setShowPassword(!showPassword)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)
  const handleClickPasswordConfirmation = () => setShowPasswordConfirmation(!showPasswordConfirmation)

  const passwordValue = watch('password', '')

  return (
    <>
      <FormControl
        id={dictionary().PASSWORD}
        w={width}
        minH={minHight}
        display='inline-block'
        isInvalid={!!errors.password || (error as any)?.data?.message[0] === 'password must be shorter than or equal to 20 characters'}
      >
        <FormLabel>
          {dictionary().PASSWORD} {isPasswordRequired && <Reqiured />}
        </FormLabel>

        <InputGroup size='md'>
          <Input
            data-test={dictionary().PASSWORD}
            pr='1rem'
            {...register('password', {
              required: isPasswordRequired && dictionary().REQUIRED,
              pattern: {
                value: /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                message: dictionary().WEAK_PASSWORD,
              },
            })}
            type={showPassword ? 'text' : 'password'}
          />

          <InputLeftElement display='flex' justifyContent='center' alignItems='center' cursor='pointer' paddingBottom={'0.3rem'}>
            <span onClick={handleClickPassword}>{showPassword ? <ViewIcon /> : <ViewOffIcon />}</span>
          </InputLeftElement>
        </InputGroup>
        <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        <FormErrorMessage>
          {(error as any)?.data?.message[0] === 'password must be shorter than or equal to 20 characters' && dictionary().INVALID_PASSWORD_LENGTH}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        id={dictionary().PASSWORD_CONFIRMATION}
        w={width}
        minH={minHight}
        display='inline-block'
        isInvalid={!!errors.confirmedPass && !!passwordValue}
      >
        <FormLabel>
          {dictionary().PASSWORD_CONFIRMATION} {passwordValue && <Reqiured />}
        </FormLabel>
        <InputGroup size='md'>
          <Input
            data-test={dictionary().PASSWORD_CONFIRMATION}
            required
            id='confirmedPass'
            pr='1rem'
            type={showPasswordConfirmation ? 'text' : 'password'}
            {...register('confirmedPass', {
              required: passwordValue && dictionary().REQUIRED,
              validate: (value: any) => {
                if (value !== passwordValue) return dictionary().PASSWORDS_NOT_MATCHED
              },
            })}
          />
          <InputLeftElement display='flex' justifyContent='center' alignItems='center' cursor='pointer' paddingBottom={'0.3rem'}>
            <span onClick={handleClickPasswordConfirmation}>{showPasswordConfirmation ? <ViewIcon /> : <ViewOffIcon />}</span>
          </InputLeftElement>
        </InputGroup>
        <FormErrorMessage>{errors.confirmedPass?.message}</FormErrorMessage>
      </FormControl>
    </>
  )
}

export default PasswordAndConfirmation
