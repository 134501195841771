import React from 'react'

import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'brand_name',
    key: 'brand_name',
    name: dictionary().BRAND_NAME,
    type: 'text',
  },
  {
    id: 'editBrand',
    key: 'editBrand',
    name: dictionary().ACTIONS,
    type: 'modal',
  },
]

const VehicleBrands: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().VEHICLE_BRANDS}
      to='/vehicle-brands/add'
      hasSearch={false}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['vehicle-brands']}
      countArrayPath={['count']}
      restRoute={'/vehicle-brand'}
      cashingKey={CASHING_KEY.GET_VEHICLE_BRANDS}
      enableIdRedirect={false}
    />
  )
}

export default VehicleBrands
