import React, { useCallback, useRef, useState } from 'react'
import { path } from 'ramda'
import { Button, Flex, Grid, GridItem, Text, Stack, Spacer, Box, Tag } from '@chakra-ui/react'
import { useParams, Link as ReactLink, useLocation, useHistory } from 'react-router-dom'

import format from 'date-fns/format'
import { useQuery } from 'react-query'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import dictionary from '../../dictionary'
import formatAsCurrency from '../../helpers/formatAsCurrency'

import StationStaff from '../core/StationStaff'
import CorporateStaff from '../core/CorporateStaff'
import FuelTransactions from '../core/FuelTransactions'
import VehicleAssignedDrivers from '../core/VehicleAssignedDrivers'
import Balances from '../../pages/corporates/balances'
import DriverAssignedVehicles from '../../components/core/DriverAssignedVehicles'
import { CASHING_KEY } from '../../constants'
import { GridProps, RowProps } from '../../interfaces/gridProps'
import fetch from '../../helpers/fetch'
import StationProviderStaff from './StationProviderStaff'
import CorporateStationProviders from './CorporateStationProviders'
import AccessibleStationProviders from './AccessibleStationProviders'
import PaymentsTable from './PaymentsTable'
import CorporatesForGorporateGroup from './CorporatesForGorporateGroup'
import TopRow from './TopRow'
import StationPosDevices from './StationPosDevices'
import StationServices from './StationServices'
import CustomerCorporates from './CustomerCorporates'
import CustomerDistinctiveTaxNumbers from './CustomerDistinctiveTaxNumbers'
import VehicleNFC from './VehicleNFC'
import { useStore } from '../../store'
import { ROLES } from '../../constants/ROLES'

const handleImage = (header: any, value: any) => {
  let srcImg = ''
  if (header.key == 'icon') {
    srcImg = value.split('/')[value.split('/').length - 2]
  }
  return value ? <img style={{ width: '35', height: '35px' }} src={`/assets/${srcImg}`} /> : '-'
}

const handleBooleanValue = (header: any, value: any) => {
  let returnedValue = ''
  if (header.key == 'has_printing') {
    value ? (returnedValue = dictionary().PRINTER) : (returnedValue = dictionary().NO_PRINTER)
  } else if (header.key == 'is_demo') {
    value ? (returnedValue = dictionary().IS_DEMO) : (returnedValue = dictionary().NOT_DEMO)
  } else if (header.key == 'allow_negative_balance') {
    value ? (returnedValue = dictionary().NEGATIVE_BALANCE_IS_ALLOWED) : (returnedValue = dictionary().NEGATIVE_BALANCE_NOT_ALLOWED)
  } else if (header.key == 'odometer_first') {
    value ? (returnedValue = dictionary().ODOMETER_FIRST) : (returnedValue = dictionary().ODOMETER_NOT_FIRST)
  } else if (header.key == 'all_vehicles_access') {
    value ? (returnedValue = dictionary().ALL_VEHICLES_ACCESS) : (returnedValue = dictionary().ALL_VEHICLES_NOT_ACCESS)
  } else if (header.key == 'is_odometer_camera_enabled') {
    value ? (returnedValue = dictionary().IS_ODOMETER_CAMERA_ENABLED) : (returnedValue = dictionary().ODOMETER_CAMERA_NOT_ENABLED)
  } else if (header.key == 'use_liters') {
    value ? (returnedValue = dictionary().USE_LITERS) : (returnedValue = dictionary().NOT_USE_LITERS)
  } else if (header.key == 'is_advanced') {
    value ? (returnedValue = dictionary().ADVANCED_USER) : (returnedValue = dictionary().NOT_ADVANCED_USER)
  } else if (header.key == 'is_active' || header.key == 'vehicle.is_active') {
    value ? (returnedValue = dictionary().IS_ACTIVE) : (returnedValue = dictionary().NOT_ACTIVE)
  } else if (header.key == 'use_shifts') {
    value ? (returnedValue = dictionary().USE_SHIFTS) : (returnedValue = dictionary().NO_SHIFTS)
  } else if (header.key == 'show_reports') {
    value ? (returnedValue = dictionary().SHOW_REPORTS) : (returnedValue = dictionary().HIDE_REPORTS)
  } else if (header.key == 'show_balance') {
    value ? (returnedValue = dictionary().SHOW_BALANCE) : (returnedValue = dictionary().HIDE_BALANCE)
  } else if (header.key === 'show_for_drivers') {
    return value ? <> {dictionary().SHOW_STATION_FOR_ALL_DRIVERS}</> : <>{dictionary().DONT_SHOW_STATION_FOR_ALL_DRIVERS}</>
  } else if (header.key === 'config.live_ocr.enabled' || header.key === 'config.live_ocr.auto_skip') {
    return value ? <> {dictionary().ACTIVE}</> : <>{dictionary().INACTIVE}</>
  } else if (header.key === 'config.live_ocr.skip') {
    return value ? <> {dictionary().ALLOW_SKIP}</> : <>{dictionary().NO_SKIP}</>
  } else {
    value ? (returnedValue = dictionary().OFFLINE_PIN) : (returnedValue = dictionary().QR_CODE)
  }
  return returnedValue
}

const handleTextValue = (header: any, value: any, corporateGroups: any, stationProvider: any) => {
  let returnedValue = ''
  if (header.key == 'settlement') {
    value === 'PRE_PAID' ? (returnedValue = dictionary().PRE_PAID) : (returnedValue = dictionary().POST_PAID)
  } else if (header.key == 'tips_type') {
    value === 'percentage' && (returnedValue = dictionary().PERCENTAGE)
    value === 'unit' && (returnedValue = dictionary().PAISTRES)
  } else if (header.key == 'fuelTypes') {
    if (value?.length > 0) {
      const namesArray = value?.map((item: any) => item?.name?.ar)
      returnedValue = namesArray.join(' - ')
    } else {
      returnedValue = '-'
    }
  } else if (header.key == 'billing_period') {
    value === 'MONTHLY'
      ? (returnedValue = dictionary().MONTHLY)
      : value === 'WEEKLY'
      ? (returnedValue = dictionary().WEEKLY)
      : (returnedValue = dictionary().BIWEEKLY)
  } else if (header.key == 'replenish_type') {
    value === 'MONTHLY'
      ? (returnedValue = dictionary().MONTHLY)
      : value === 'WEEKLY'
      ? (returnedValue = dictionary().WEEKLY)
      : value === 'DAILY'
      ? (returnedValue = dictionary().DAILY)
      : (returnedValue = '-')
  } else if (header.key == 'consumption_measurement') {
    value === 'L_PER_HUNDRED_KM'
      ? (returnedValue = dictionary().LITERS_PER_HUNDRED_KM)
      : value === 'L_PER_KM'
      ? (returnedValue = dictionary().LITERS_PER_KM)
      : value === 'KM_PER_L'
      ? (returnedValue = dictionary().KM_PER_LITERS)
      : (returnedValue = '-')
  } else if (header.key == 'corporateGroupId') {
    const corporateGroup = corporateGroups?.data?.data?.find((item: any) => item.id === value)
    return value !== null ? corporateGroup?.name : '-'
  } else if (header.key == 'providerId') {
    return value !== null ? stationProvider?.data?.name?.ar : '-'
  } else if (header.key == 'deposit_confirmation_type') {
    return value !== null ? dictionary()[value as keyof typeof dictionary] : '-'
  } else if (header.key == 'dimensions') {
    return value ? `(${value.width} * ${value.height} * ${value.depth}) م` : '-'
  } else {
    returnedValue = value
  }
  return returnedValue
}

export const Row: React.FC<RowProps> = ({ header, row }) => {
  const { pathname } = useLocation()
  const { push } = useHistory()
  let corporateGroups: any = []
  let stationProvider: any = []
  pathname.includes('/corporates') &&
    header.key == 'corporateGroupId' &&
    (corporateGroups = useQuery(['corporate-group'], () => fetch('GET', `/corporate-group`), {}))
  pathname.includes('/stations') &&
    header.key == 'providerId' &&
    (stationProvider = useQuery(['station-provider'], () => fetch('GET', `/station-providers/${row?.providerId}`), {}))
  const value = header.format ? header.format(path(header.key.split('.'), row), row) : path(header.key.split('.'), row)
  switch (header.type) {
    case 'time':
      const time = value && format(new Date(value as any), 'MM/dd/yyyyHH:mm:ss')
      return (
        <GridItem colSpan={3}>
          <Stack m={2} direction='row' h='100'>
            <Box w='100%'>
              <Text mt={2} fontSize='l' color='grey'>
                {header.name}
              </Text>
              <Text mt={0} pb='0' fontSize='19px'>
                {(time as any)?.substring(10, 19).replace(/T/g, '   ')}
              </Text>
            </Box>
          </Stack>
        </GridItem>
      )
    case 'date':
      return (
        <GridItem colSpan={3}>
          <Stack m={2} direction='row'>
            <Box w='100%' pr='0'>
              <Text mt={2} fontSize='l' color='grey'>
                {header.name}
              </Text>
              <Text mt={0} pb='0' fontSize='19px'>
                {(value as any)?.substring(0, 10).replace(/-/g, '/').replace(/T/g, '   ')}
              </Text>
            </Box>
          </Stack>
        </GridItem>
      )
    case 'text':
      if (header?.id === 'shift_starting' && row?.use_shifts === false) return <></>
      if (header?.id === 'daily_replenish_time' && row?.replenish_type !== 'DAILY') return <></>
      return (
        <GridItem colSpan={3}>
          <Stack m={2} direction='row'>
            <Box w='100%' pr='0'>
              <Text fontSize='l' color='grey'>
                {header.name}
              </Text>
              <Text mt={0} fontSize='xl'>
                {handleTextValue(header, value, corporateGroups, stationProvider)}
              </Text>
            </Box>
          </Stack>
        </GridItem>
      )
    case 'boolean':
      if (header?.id === 'config.live_ocr.skip' && row?.config?.live_ocr?.enabled === false) return <></>
      if (header?.id === 'config.live_ocr.auto_skip' && row?.config?.live_ocr?.enabled === false) return <></>
      return (
        <GridItem colSpan={3}>
          <Stack m={2} direction='row'>
            <Box w='100%' pr='0'>
              <Text fontSize='l' color='grey'>
                {header.name}
              </Text>
              <Text mt={0} fontSize='xl'>
                {handleBooleanValue(header, value)}
              </Text>
            </Box>
          </Stack>
        </GridItem>
      )
    case 'img':
      return (
        <GridItem colSpan={3}>
          <Stack m={2} direction='row'>
            <Box w='100%' pr='0'>
              <Text fontSize='l' color='grey'>
                {header.name}
              </Text>
              <Text mt={0} fontSize='xl'>
                {handleImage(header, value)}
              </Text>
            </Box>
          </Stack>
        </GridItem>
      )
    case 'cash':
      return (
        <>
          {' '}
          <GridItem colSpan={3}>
            <Stack my={2} direction='row'>
              <Box w='100%' pr='0'>
                <Text fontSize='l' color='grey'>
                  {header.name}
                </Text>
                <Text mt={0} fontSize='xl'>
                  {formatAsCurrency(value as any)}
                </Text>
              </Box>
            </Stack>
          </GridItem>{' '}
        </>
      )
    case 'fuel-types':
      return (
        <GridItem colSpan={3}>
          <Stack m={2} direction='row' h='100'>
            <Box w='100%'>
              <Text mt={2} fontSize='l' color='grey'>
                {header.name}
              </Text>
              {(value as any)?.map((item: any) => (
                <Tag fontSize='2xl' m='1' p='1' key={item?.id}>
                  {item?.name?.ar}
                </Tag>
              ))}
            </Box>
          </Stack>
        </GridItem>
      )
    case 'table':
      const { id } = useParams<{ id: string }>()
      return (
        <GridItem colSpan={3}>
          <Stack m={2} direction='row'>
            <Box w='100%' pr='4'>
              <Button disabled={value === 0} onClick={() => push(`/station-providers/${id}/stations`)} colorScheme='gray' variant='solid' width='50%'>
                {dictionary().STATIONS}
              </Button>
            </Box>
          </Stack>
        </GridItem>
      )
  }
  return <></>
}

const Grids: React.FC<GridProps> = ({ cashingKey, isLoading, header, data, title, actions, redirect, userType, handleIsBalanseChanged }) => {
  const { role } = useStore()
  const { pathname } = useLocation()
  const headerRef = useRef<HTMLDivElement>(null)
  const [isBalancesChange, setIsBalancesChange] = useState(false)
  const [isDriverAssigned, setIsDriverAssigned] = useState(false)
  const [isCorporateSPUnbilled, setIsCorporateSPUnbilled] = useState(false)
  const [isCorporateAccessSP, setIsCorporateAccessSP] = useState(false)

  const handleIsDriverAssigned = useCallback(() => {
    setIsDriverAssigned(!isDriverAssigned)
  }, [isDriverAssigned])
  const handleIsBalancesChange = useCallback(() => {
    setIsBalancesChange(!isBalancesChange)
  }, [isBalancesChange])
  const handleIsCorporateSPUnbilled = useCallback(() => {
    setIsCorporateSPUnbilled(!isCorporateSPUnbilled)
  }, [isCorporateSPUnbilled])
  const handleIsCorporateAccessSP = useCallback(() => {
    setIsCorporateAccessSP(!isCorporateAccessSP)
  }, [isCorporateAccessSP])

  const findName = (data: any) => {
    if (data?.name?.ar) {
      return data?.name?.ar
    } else if (data?.name) {
      return data?.name
    } else if (data?.data?.name) {
      return data?.data?.name
    } else if (data?.stationProvider) {
      return data?.stationProvider?.name?.ar
    } else return data?.id
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        {isLoading ? (
          <Header isLoading={isLoading} redirect={redirect} actions={actions} title={title + ' :'} />
        ) : (
          <Header
            id={data?.id}
            isLoading={isLoading}
            redirect={redirect}
            actions={actions}
            title={title + ' : ' + findName(data)}
            handleIsBalancesChange={handleIsBalancesChange}
            handleIsDriverAssigned={handleIsDriverAssigned}
            handleIsCorporateSPUnbilled={handleIsCorporateSPUnbilled}
            handleIsCorporateAccessSP={handleIsCorporateAccessSP}
            handleIsBalanseChanged={handleIsBalanseChanged}
            data={data}
          />
        )}
      </Flex>

      <Grid templateColumns='repeat(8, 1fr)' gap={8} pl={0}>
        {isLoading ? (
          <GridItem colSpan={8}>
            <Text fontSize='2xl'>
              {dictionary().SEARCH_LOADING} {dictionary().IN}{' '}
              {cashingKey === CASHING_KEY.GET_SINGLE_VEHICLE ? (
                <>{dictionary().VEHICLES}</>
              ) : cashingKey === CASHING_KEY.GET_SINGLE_CORPORATE ? (
                <>{dictionary().CORPORATES}</>
              ) : cashingKey === CASHING_KEY.GET_SINGLE_VEHICLE_TYPE ? (
                <>{title}</>
              ) : (
                <></>
              )}
            </Text>
          </GridItem>
        ) : (
          <>
            <>
              <GridItem colSpan={8}>
                <Flex mt='0' direction='row' display={['block', 'flex', 'flex', 'flex']}>
                  {header.map((h: any) => (
                    <>
                      <Spacer />
                      <TopRow redirect={redirect} header={h} row={data} isLoading={isLoading} />
                      <Spacer />
                    </>
                  ))}
                </Flex>
              </GridItem>
              <GridItem colSpan={[12, 4, 4, 4]}>
                <Card h='100%' p={3}>
                  <Text m={3} fontSize='20px'>
                    {dictionary().PERSONAL_INFO}
                  </Text>
                  <Grid templateColumns='repeat(6, 1fr)'>
                    {header.map((h: any) => (
                      <>
                        <Row redirect={redirect} header={h} row={data.data ? data.data : data} isLoading={isLoading} />
                      </>
                    ))}
                  </Grid>
                </Card>
              </GridItem>
              {cashingKey === CASHING_KEY.GET_SINGLE_CUSTOMER && (
                <>
                  <GridItem colSpan={[12, 4, 4, 4]}>
                    <CustomerCorporates />
                  </GridItem>
                  <GridItem colSpan={[12, 4, 4, 4]}>
                    <CustomerDistinctiveTaxNumbers />
                  </GridItem>
                </>
              )}
              <GridItem colSpan={[12, 4, 4, 4]}>
                {cashingKey === CASHING_KEY.GET_SINGLE_CORPORATE || cashingKey === CASHING_KEY.GET_SINGLE_CORPORATE_GROUP ? (
                  <>
                    <CorporateStaff
                      restRoute={pathname.includes('corporates') ? '/corporate-staff?corporateId=' : '/corporate-group-staff?corporateGroupId='}
                    />
                  </>
                ) : cashingKey === CASHING_KEY.GET_SINGLE_CORPORATE_GROUP ? (
                  <CorporatesForGorporateGroup restRoute='/corporate-group' />
                ) : cashingKey === CASHING_KEY.GET_SINGLE_STATION ? (
                  <StationStaff restRoute='/station-staff?stationId=' />
                ) : cashingKey === CASHING_KEY.GET_SINGLE_STATION_PROVIDER ? (
                  <StationProviderStaff restRoute='/station-provider-staff?stationProviderId=' />
                ) : cashingKey === CASHING_KEY.GET_SINGLE_VEHICLE ? (
                  <VehicleAssignedDrivers restRoute={`/vehicles/`} isDriverAssigned={isDriverAssigned} />
                ) : cashingKey === CASHING_KEY.GET_SINGLE_DRIVER ? (
                  <DriverAssignedVehicles restRoute={`/drivers/`} />
                ) : (
                  <></>
                )}
              </GridItem>
              {(cashingKey === CASHING_KEY.GET_SINGLE_CORPORATE || cashingKey === CASHING_KEY.GET_SINGLE_STATION_PROVIDER) && (
                <GridItem colSpan={[12, 4, 4, 4]}>
                  <PaymentsTable />
                </GridItem>
              )}
              {[ROLES.SUPER_ADMIN, ROLES.MANAGER].includes(role as string) && cashingKey === CASHING_KEY.GET_SINGLE_VEHICLE && (
                <GridItem colSpan={[12, 4, 4, 4]}>
                  <VehicleNFC />
                </GridItem>
              )}
              {cashingKey === CASHING_KEY.GET_SINGLE_CORPORATE && (
                <>
                  <GridItem colSpan={[12, 4, 4, 4]}>
                    <CorporateStationProviders isCorporateSPUnbilled={isCorporateSPUnbilled} />
                  </GridItem>
                  <GridItem colSpan={[12, 4, 4, 4]}>
                    <AccessibleStationProviders isCorporateAccessSP={isCorporateAccessSP} />
                  </GridItem>
                </>
              )}
            </>
            {
              // cashingKey === CASHING_KEY.GET_SINGLE_CORPORATE ||
              cashingKey === CASHING_KEY.GET_SINGLE_DRIVER ||
              cashingKey === CASHING_KEY.GET_SINGLE_STATION ||
              cashingKey === CASHING_KEY.GET_SINGLE_STATION_PROVIDER ||
              cashingKey === CASHING_KEY.GET_VEHICLE ? (
                <GridItem colSpan={[12, 4, 4, 4]}>
                  <FuelTransactions restRoute='/fuel-transactions?' cashingKey={CASHING_KEY.GET_FUEL_TRANSACTIONS_FOR_ID} height='300' userType={userType} />
                </GridItem>
              ) : (
                <></>
              )
            }
            {cashingKey === CASHING_KEY.GET_SINGLE_CORPORATE && <Balances isBalancesChange={isBalancesChange} />}
            {cashingKey === CASHING_KEY.GET_SINGLE_STATION && (
              <>
                <GridItem colSpan={[12, 4, 4, 4]}>
                  <StationPosDevices />
                </GridItem>
                <GridItem colSpan={[12, 4, 4, 4]}>
                  <StationServices />
                </GridItem>
              </>
            )}
          </>
        )}
      </Grid>
    </Container>
  )
}

export default Grids
