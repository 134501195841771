export enum CASHING_KEY {
  GET_VEHICLE = 'getVehicle',
  GET_SINGLE_VEHICLE = 'getSingleVehicle',
  GET_SINGLE_VEHICLE_TYPE = 'getSingleVehicleType',
  GET_VEHICLE_TYPES = 'getVehicleTypes',
  GET_TRIPS = 'getTrips',
  GET_SINGLE_TRIP = 'getSingleTrip',
  GET_TOLL_TRANSACTIONS_FOR_ID = 'gettoll-transactionsForID',
  GET_TOLL_TRANSACTIONS = 'getTollTransactions',
  GET_TOLL_PRICES = 'getTollPrices',
  GET_STORE_TRANSACTIONS = 'getStoreTransactions',
  GET_LEDGER_TRANSACTIONS_FOR_ID = 'getLedger-transactionsForID',
  GET_FUEL_TRANSACTIONS_FOR_DATA_ENTRY = 'getFuelTransactionsForDataEntry',
  GET_FUEL_TRANSACTIONS_FOR_ID = 'getFuel-transactionsForID',
  GET_FUEL_TRANSACTIONS = 'getFuelTransactions',
  GET_STORE_PRODUCSTS = 'getStoreProducts',
  GET_SINGLE_STORE_PRODUCT = 'getSingleStoreProduct',
  GET_STATIONS = 'getStations',
  GET_SINGLE_STATION = 'getSingleStation',
  GET_STATION_PROVIDERS = 'getStation-providers',
  GET_STATION_PROVIDERS_STATIONS = 'getStationProvidersStations',
  GET_SINGLE_STATION_PROVIDER = 'getSingleStationProvider',
  GET_STATIONS_ACCOUNTS = 'getStationsAccounts',
  GET_PRODUCT_TYPES = 'getProductTypes',
  GET_FUEL_TYPE = 'getFuelType',
  GET_SINGLE_FUEL = 'getSingleFuel',
  GET_SINGLE_DRIVER = 'getSingleDriver',
  GET_DRIVERS = 'getDrivers',
  GET_SINGLE_CORPORATE = 'getSingleCorporate',
  GET_CORPORATES = 'getCorporates',
  GET_SINGLE_CORPORATE_GROUP = 'getSingleCorporateGroup',
  GET_CORPORATE_GROUPS = 'getCorporateGroups',
  GET_CORPORATES_ACCOUNTS = 'getCorporatesAccounts',
  GET_ADMINS = 'getAdmins',
  INVOICES = 'invoices',
  TRANSACTIONS_REVIEWED = 'transactionsReviewed',
  VEHICLE_SUBSCRIPTIONS = 'vehicleSubscriptions',
  SP_VEHICLE_SUBSCRIPTIONS = 'sp_vehicleSupscriptions',
  GET_CORPORATE_DRIVERS = 'getCorporateDrivers',
  GET_CUSTOMERS = 'getCustomers',
  GET_SINGLE_CUSTOMER = 'getSingleCustomer',
  GET_CHARGING_PROFILES = 'getChargingProfiles',
  GET_DUPLICTE_TRANSACTIONS = 'getDuplicateTransactions',
  GET_CONFITMED_PAYMENTS = 'getConfirmedPayments',
  GET_PAYMENTS_DETAILS = 'getPaymentsDetails',
  E_INVOICES = 'E-Invoices',
  GET_POS_DEVICES = 'getPosDevices',
  GET_VEHICLE_MODELS = 'getVehicleModels',
  GET_VEHICLE_BRANDS = 'getVehicleBrands',
  GET_SINGLE_VEHICLE_BRAND = 'getSingleVehicleBrand',
  GET_TAX_IDS = 'getTaxIds',
}

export enum USER_TYPE {
  ADMIN = 'admin',
  STATION_STAFF = 'station-staff',
  STATION_PROVIDER_STAFF = 'station-provider-staff',
  CORPORATE_STAFF = 'corporate-staff',
  CORPORATE_GROUP_STAFF = 'corporate-group-staff',
}
