import React, { useEffect, useRef } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'

type EditVehicleModelVariables = {
  name_en: string
  name_ar: string
  price: number
}

const EditVehicleModel: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    control,
    setValue,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const { mutate: getModelData } = useMutation(() => fetch('GET', `/vehicle-model/${id}`), {
    onSuccess: (data: any): void => {
      setValue('vehicleBrandId', [data?.vehicleBrand?.id])
      setValue('vehicleTypeId', [data?.vehicleType?.id])
      setValue('modelName', data?.name)
    },
  })

  useEffect(() => {
    getModelData()
  }, [])

  const { isLoading, mutate } = useMutation((vars: any) => fetch('PATCH', '/vehicle-model', { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      push('/vehicle-models')
    },
    // onError,
  })
  const editVehicleModel = async (formData: any) => {
    formData.id = +id
    formData.vehicleBrandId = formData?.vehicleBrandId[0]
    formData.vehicleTypeId = formData?.vehicleTypeId[0]
    formData.modelName = formData?.modelName?.trim()
    mutate(formData)
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().ADD_NEW_FUEL}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && editVehicleModel(getValues() as EditVehicleModelVariables)} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(editVehicleModel as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <FormControl
              id={dictionary().VEHICLE_BRAND}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.vehicleBrandId}
            >
              <FormLabel>
                {dictionary().VEHICLE_BRAND} <Reqiured />
              </FormLabel>
              <Controller
                name={`vehicleBrandId`}
                rules={{ required: dictionary().REQUIRED }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    isInvalid={!!errors?.vehicleBrandId}
                    onChange={onChange}
                    value={value}
                    dataTest={dictionary().VEHICLE_BRAND}
                    searchRoute='/vehicle-brand'
                    singleRoute='/vehicle-brand'
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.brand_name}
                  />
                )}
              />
              <FormErrorMessage>{errors?.vehicleBrandId?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().VEHICLE_TYPE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.vehicleTypeId}
            >
              <FormLabel>
                {dictionary().VEHICLE_TYPE} <Reqiured />
              </FormLabel>
              <Controller
                name={`vehicleTypeId`}
                rules={{ required: dictionary().REQUIRED }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    isInvalid={!!errors?.vehicleTypeId}
                    onChange={onChange}
                    value={value}
                    dataTest={dictionary().VEHICLE_TYPE}
                    searchRoute='/vehicle-types'
                    singleRoute='/vehicle-types'
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.name?.ar}
                  />
                )}
              />
              <FormErrorMessage>{errors?.vehicleTypeId?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().VEHICLE_MODEL}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.modelName}
            >
              <FormLabel>
                {dictionary().VEHICLE_MODEL} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  data-test={dictionary().VEHICLE_MODEL}
                  {...register('modelName', {
                    required: dictionary().REQUIRED,
                    pattern: {
                      value: /^[a-zA-Z0-9\u0600-\u06FF]+[a-zA-Z0-9\u0600-\u06FF ]*$/,
                      message: dictionary().ADD_VALID_MODEL_NAME,
                    },
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.modelName?.message}</FormErrorMessage>
            </FormControl>
            <FormControl w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']}></FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default EditVehicleModel
