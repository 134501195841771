import { Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Input, InputGroup, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import Card from '../../components/core/Card'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import PasswordAndConfirmation from '../../components/core/PasswordAndConfirmation'
import PasswordAndConfirmationGrid from '../../components/core/PasswordAndConfirmationGrid'
import Reqiured from '../../components/core/reqiured'
import SaveButton from '../../components/core/SaveButton'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'

const AddStation: React.FC = () => {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    watch,
    setValue,
    trigger,
  } = useForm({ mode: 'onChange' })
  const stationConfig = watch('station_config')
  const services = watch('services')
  const [showServiceError, setShowServiceError] = useState(false)

  const headerRef = useRef<HTMLDivElement>(null)
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/
  const phoneRegex = /^01[0125][0-9]{8}$/
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', '/stations', { data: vars }), {
    onSuccess: (): void => {
      push('/stations')
      onSuccess()
    },
  })

  useEffect(() => {
    setValue('show_for_drivers', true)
  }, [])
  useEffect(() => {
    setValue('live_ocr', null)
    setValue('auto_skip', null)
  }, [stationConfig])

  useEffect(() => {
    setShowServiceError(false)
  }, [watch('enable_car_wash'), watch('enable_oil_change')])

  const addStation = async () => {
    if (getValues()?.coordinates?.toString().replace(/\s+/g, '')?.split(',').length < 2) {
      alert(`شكل الإحداثيات لابد أن يكون هكذا : العرض,الطول
           مثال :  562,245
      `)
    }

    mutate([
      {
        name: { en: getValues()?.name?.en.trim(), ar: getValues()?.name?.ar.trim() },
        station_provider_id: getValues()?.station_provider_id[0],
        availableFuelTypeIds: getValues()?.availableFuelTypeIds,
        city: getValues()?.city,
        address: getValues()?.address.trim(),
        road: getValues()?.road.trim(),
        type: 'FUEL',
        username: getValues()?.username.trim(),
        password: getValues()?.password,
        contact_person: {
          name: getValues()?.contact_person?.name.trim(),
          phone: getValues()?.contact_person?.phone,
          email: getValues()?.contact_person?.email.trim(),
        },
        location: {
          latitude: Number(getValues()?.coordinates?.replace(/\s+/g, '')?.split(',')[0].trim()),
          longitude: Number(getValues()?.coordinates?.replace(/\s+/g, '')?.split(',')[1].trim()),
        },
        code: getValues()?.code.trim(),
        use_liters: getValues()?.use_liters,
        odometer_first: getValues()?.odometer_first,
        show_for_drivers: getValues()?.show_for_drivers,
        tips_percentage: Number(getValues()?.tips_percentage),
        config: {
          live_ocr: {
            enabled: stationConfig,
            skip: getValues()?.live_ocr === 'allow_skip',
            auto_skip: getValues()?.auto__skip,
          },
        },
        enable_car_wash: services && getValues()?.enable_car_wash,
        enable_oil_change: services && getValues()?.enable_oil_change,
      },
    ])
  }

  const shouldSelectOneService = (): boolean => {
    const throwServiceError = services && !watch('enable_car_wash') && !watch('enable_oil_change')
    setShowServiceError(throwServiceError)
    return !throwServiceError
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().ADD_NEW_STATION}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && shouldSelectOneService() && addStation()} />}
        />
      </Flex>
      <form onSubmit={handleSubmit(addStation as any)}>
        <Card p='8'>
          <Grid templateColumns='repeat(12, 1fr)' gap={6}>
            <GridItem colSpan={12} pb={5}>
              <Text fontSize='20px' fontWeight='700'>
                {dictionary().MAIN_INFO}
              </Text>
              <hr style={{ margin: '10px 0' }} />
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl id={dictionary().STATION_NAME_AR} isInvalid={!!errors?.name?.ar}>
                <FormLabel>
                  {dictionary().STATION_NAME_AR} <Reqiured />
                </FormLabel>

                <InputGroup>
                  <Input
                    data-test={dictionary().STATION_NAME_AR}
                    type='text'
                    {...register('name.ar', {
                      required: dictionary().REQUIRED,
                      validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.name?.ar?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl id={dictionary().STATION_NAME_EN} isInvalid={!!errors?.name?.en}>
                <FormLabel>
                  {dictionary().STATION_NAME_EN} <Reqiured />
                </FormLabel>

                <InputGroup>
                  <Input
                    data-test={dictionary().STATION_NAME_EN}
                    type='text'
                    {...register('name.en', {
                      required: dictionary().REQUIRED,
                      validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.name?.en?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl
                id={dictionary().USERNAME}
                isInvalid={!!errors.username || (error as any)?.data?.message === `Station staff with given username '${getValues().username}' already exists`}
              >
                <FormLabel>
                  {dictionary().USERNAME}
                  <Reqiured />
                </FormLabel>
                <InputGroup>
                  <Input
                    data-test={dictionary().USERNAME}
                    type='text'
                    {...register('username', {
                      required: dictionary().REQUIRED,
                      validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.username?.message}</FormErrorMessage>
                <FormErrorMessage>
                  {(error as any)?.data?.message === `Station staff with given username '${getValues().username}' already exists` &&
                    dictionary().THIS_USER_NAME_IS_ALREADY_TAKEN}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl id={dictionary().CODE} isInvalid={!!errors.code || (error as any)?.data?.message === 'this code is already exist'}>
                <FormLabel>
                  {dictionary().CODE}
                  <Reqiured />
                </FormLabel>

                <InputGroup>
                  <Input
                    data-test={dictionary().CODE}
                    type='text'
                    {...register('code', {
                      required: dictionary().REQUIRED,
                      validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.code?.message}</FormErrorMessage>
                <FormErrorMessage>{(error as any)?.data?.message === `this code is already exist` && dictionary().THIS_CODE_IS_ALREADY_TAKEN}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <PasswordAndConfirmationGrid register={register} errors={errors} watch={watch} col={[12, 12, 6, 4]} />

            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl id={dictionary().CITY} isInvalid={!!errors.city}>
                <FormLabel>{dictionary().CITY}</FormLabel>
                <InputGroup>
                  <Input data-test={dictionary().CITY} type='text' {...register('city', {})} />
                </InputGroup>
                <FormErrorMessage>{errors?.city?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl id={dictionary().STATION_ADDRESS} isInvalid={!!errors.address}>
                <FormLabel>{dictionary().STATION_ADDRESS}</FormLabel>
                <InputGroup>
                  <Input data-test={dictionary().STATION_ADDRESS} type='text' {...register('address', {})} />
                </InputGroup>

                <FormErrorMessage>{errors?.address?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl id={dictionary().STATION_ROAD} isInvalid={!!errors.road}>
                <FormLabel>{dictionary().STATION_ROAD}</FormLabel>
                <InputGroup>
                  <Input data-test={dictionary().STATION_ROAD} type='text' {...register('road', {})} />
                </InputGroup>

                <FormErrorMessage>{errors?.road?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl id={dictionary().CHOOSE_STATION_PROVIDER} isInvalid={!!errors?.station_provider_id}>
                <FormLabel>
                  {dictionary().CHOOSE_STATION_PROVIDER}
                  <Reqiured />
                </FormLabel>

                <Controller
                  name={`station_provider_id`}
                  rules={{ required: dictionary().REQUIRED }}
                  control={control}
                  render={({ field: { onChange } }: any) => (
                    <AutoCompleteInput
                      dataTest={dictionary().CHOOSE_STATION_PROVIDER}
                      isInvalid={!!errors?.station_provider_id}
                      onChange={onChange}
                      searchRoute='/station-providers'
                      singleRoute='/station-providers'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.name?.ar}
                    />
                  )}
                />
                <FormErrorMessage>{errors?.station_provider_id?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl
                id={dictionary().COORDINATES}
                isInvalid={
                  !!errors.coordinates || (error as any)?.data?.message[1] === 'location.longitude must be a number conforming to the specified constraints'
                }
              >
                <FormLabel>
                  {dictionary().COORDINATES}
                  <Reqiured />
                </FormLabel>

                <InputGroup>
                  <Input
                    data-test={dictionary().COORDINATES}
                    type='text'
                    {...register('coordinates', {
                      required: dictionary().REQUIRED,
                      validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.coordinates?.message}</FormErrorMessage>
                <FormErrorMessage>
                  {(error as any)?.data?.message[1] === 'location.longitude must be a number conforming to the specified constraints' &&
                    dictionary().INVALID_COORDINATES}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl id={dictionary().FUEL_TYPES} isInvalid={!!errors?.availableFuelTypeIds}>
                <FormLabel>
                  {dictionary().FUEL_TYPES}
                  {/* <Reqiured /> */}
                </FormLabel>

                <Controller
                  name={`availableFuelTypeIds`}
                  // rules={{ required: dictionary().REQUIRED }}
                  control={control}
                  render={({ field: { onChange } }: any) => (
                    <AutoCompleteInput
                      dataTest={dictionary().FUEL_TYPES}
                      isInvalid={!!errors?.availableFuelTypeIds}
                      onChange={onChange}
                      searchRoute='/fuel-types'
                      singleRoute='/fuel-types'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.name?.ar}
                      multiple={true}
                    />
                  )}
                />
                <FormErrorMessage>{errors?.availableFuelTypeIds?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl id={dictionary().STATION_TIPS_PER_LITER} isInvalid={!!errors?.tips_percentage}>
                <FormLabel>
                  {dictionary().STATION_TIPS_PER_LITER} <Reqiured />
                </FormLabel>
                <InputGroup>
                  <Input
                    data-test={dictionary().STATION_TIPS_PER_LITER}
                    type='number'
                    {...register('tips_percentage', {
                      required: dictionary().REQUIRED,
                      max: {
                        value: 1000,
                        message: dictionary().THIS_INPUT_CANT_BE_OVER_THOUSAND,
                      },
                      min: {
                        value: 0,
                        message: dictionary().NO_NEGATIVE_NUMBER,
                      },
                    })}
                  />
                </InputGroup>

                <FormErrorMessage>{errors?.tips_percentage?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl id={dictionary().STATIONS_CONTACT_NAME} isInvalid={!!errors?.contact_person?.name}>
                <FormLabel>
                  {dictionary().STATIONS_CONTACT_NAME}
                  <Reqiured />
                </FormLabel>

                <InputGroup>
                  <Input
                    data-test={dictionary().STATIONS_CONTACT_NAME}
                    type='text'
                    {...register('contact_person.name', {
                      required: dictionary().REQUIRED,
                      validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.contact_person?.name?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl id={dictionary().STATIONS_CONTACT_NUMBER} isInvalid={!!errors?.contact_person?.phone}>
                <FormLabel>
                  {dictionary().STATIONS_CONTACT_NUMBER}
                  <Reqiured />
                </FormLabel>

                <InputGroup>
                  <Input
                    data-test={dictionary().STATIONS_CONTACT_NUMBER}
                    type='number'
                    {...register('contact_person.phone', {
                      required: dictionary().REQUIRED,
                      validate: (value) => (!phoneRegex.test(value) ? dictionary().INVALID_PHONE : undefined),
                    })}
                  />
                </InputGroup>

                <FormErrorMessage>{errors?.contact_person?.phone?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl id={dictionary().STATIONS_CONTACT_EMAIL} isInvalid={!!errors?.contact_person?.email}>
                <FormLabel>
                  {dictionary().STATIONS_CONTACT_EMAIL}
                  <Reqiured />
                </FormLabel>

                <InputGroup>
                  <Input
                    data-test={dictionary().STATIONS_CONTACT_EMAIL}
                    type='text'
                    {...register('contact_person.email', {
                      required: dictionary().REQUIRED,
                      validate: (value) => (!emailRegex.test(value) ? dictionary().INVALID_EMAIL : undefined),
                    })}
                  />
                </InputGroup>

                <FormErrorMessage>{errors?.contact_person?.email?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
        </Card>
        <Card p='8' mt={'25px'}>
          <Grid templateColumns='repeat(12, 1fr)' gap={6}>
            <GridItem colSpan={12} pb={5}>
              <Text fontSize='20px' fontWeight='700'>
                {dictionary().SETTINGS}
              </Text>
              <hr style={{ margin: '10px 0' }} />
            </GridItem>
            <GridItem colSpan={[12, 12, 4, 3]}>
              <FormControl id={'Live OCR'}>
                <Controller
                  control={control}
                  name='station_config'
                  defaultValue={false}
                  render={({ field: { onChange } }) => (
                    <Checkbox data-test={'Live OCR'} onChange={onChange} textTransform='capitalize' value='true'>
                      Live OCR
                    </Checkbox>
                  )}
                />
              </FormControl>
            </GridItem>
            {stationConfig && (
              <>
                <GridItem colSpan={[12, 12, 4, 3]}>
                  <FormControl isInvalid={!!errors.live_ocr}>
                    <FormLabel>
                      إعدادات Live OCR <Reqiured />
                    </FormLabel>
                    <Controller
                      name='live_ocr'
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction='row'>
                            <Radio value='allow_skip' color='purple'>
                              {dictionary().ALLOW_SKIP}
                            </Radio>
                            <Radio value='no_skip' color='purple'>
                              {dictionary().NO_SKIP}
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                      rules={{
                        required: true && dictionary().REQUIRED,
                      }}
                    />
                    <FormErrorMessage>{errors.live_ocr?.message}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={[12, 12, 4, 3]}>
                  <FormControl id={'Live OCR'}>
                    <Controller
                      control={control}
                      name='auto_skip'
                      defaultValue={false}
                      render={({ field: { onChange } }) => (
                        <Checkbox data-test={dictionary().ALLOW_AUTO_SKIP} onChange={onChange} textTransform='capitalize' value='true'>
                          {dictionary().ALLOW_AUTO_SKIP} Live OCR
                        </Checkbox>
                      )}
                    />
                  </FormControl>
                </GridItem>
              </>
            )}
            <GridItem colSpan={[12, 12, 4, 3]}>
              <FormControl id={dictionary().LITRES}>
                <Controller
                  control={control}
                  name='use_liters'
                  defaultValue={false}
                  render={({ field: { onChange } }) => (
                    <Checkbox data-test={dictionary().LITRES} onChange={onChange} textTransform='capitalize' value='true'>
                      {dictionary().USE_LITERS}
                    </Checkbox>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 4, 3]}>
              <FormControl id={dictionary().ODOMETER}>
                <FormLabel>{dictionary().ODOMETER}</FormLabel>
                <Controller
                  control={control}
                  name='odometer_first'
                  render={({ field: { onChange } }) => (
                    <Checkbox data-test={dictionary().ODOMETER} onChange={onChange} textTransform='capitalize' value='true'>
                      {dictionary().ODOMETER_FIRST}
                    </Checkbox>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 4, 3]}>
              <FormControl id={dictionary().SHOW}>
                <FormLabel>{dictionary().SHOW}</FormLabel>
                <Controller
                  control={control}
                  name='show_for_drivers'
                  render={({ field: { onChange } }) => (
                    <Checkbox data-test={dictionary().SHOW_STATION} onChange={onChange} textTransform='capitalize' defaultChecked value='true'>
                      {dictionary().SHOW_STATION}
                    </Checkbox>
                  )}
                />
              </FormControl>
            </GridItem>
          </Grid>
        </Card>
        <Card p='8' mt={'25px'}>
          <Grid templateColumns='repeat(12, 1fr)' gap={6}>
            <GridItem colSpan={12} pb={5}>
              <Text fontSize='20px' fontWeight='700'>
                {dictionary().CAR_SERVICES}
              </Text>
              <hr style={{ margin: '10px 0' }} />
            </GridItem>
            <GridItem colSpan={[12]}>
              <FormControl id={dictionary().SHOW}>
                <Controller
                  control={control}
                  name='services'
                  render={({ field: { onChange } }) => (
                    <Checkbox onChange={onChange} textTransform='capitalize' value='true' fontWeight={'600'} isChecked={getValues().services}>
                      {dictionary().SERVICES}
                    </Checkbox>
                  )}
                />
              </FormControl>
            </GridItem>
            {services && (
              <>
                <GridItem colSpan={[12]}>
                  <Text fontSize='16px' mt={'10px'}>
                    {dictionary().CHOOSE_FROM_SERVICES}
                  </Text>
                </GridItem>
                <GridItem colSpan={[12, 12, 6, 3]}>
                  <FormControl id={dictionary().SHOW}>
                    <Controller
                      control={control}
                      name='enable_car_wash'
                      render={({ field: { onChange } }) => (
                        <Checkbox onChange={onChange} textTransform='capitalize' value='true' isChecked={getValues().enable_car_wash}>
                          {dictionary().CAR_WASH}
                        </Checkbox>
                      )}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={[12, 12, 6, 3]}>
                  <FormControl id={dictionary().SHOW}>
                    <Controller
                      control={control}
                      name='enable_oil_change'
                      render={({ field: { onChange } }) => (
                        <Checkbox onChange={onChange} textTransform='capitalize' value='true' isChecked={getValues().enable_oil_change}>
                          {dictionary().OIL_CHANGE}
                        </Checkbox>
                      )}
                    />
                  </FormControl>
                </GridItem>
                {showServiceError && (
                  <GridItem colSpan={[12]} color='red.500' fontSize='17px'>
                    {dictionary().CHOOSE_AT_LEAST_ONE_SERVICE}
                  </GridItem>
                )}
              </>
            )}
          </Grid>
        </Card>
      </form>
    </Container>
  )
}

export default AddStation
