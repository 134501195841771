import { Flex, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import Card from '../../components/core/Card'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Required from '../../components/core/reqiured'
import SaveButton from '../../components/core/SaveButton'
import { USER_TYPE } from '../../constants'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import { useStore } from '../../store'
import AutoCompleteControl from '../transactions/components/AutoCompleteControl'

const AddVehicle: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    control,
    watch,
    setValue,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)
  const { userType } = useStore((state) => state)
  const { push } = useHistory()
  const { onSuccess } = useAlert()
  const stratingBalance = watch('starting_balance_fuel')
  const vehicle_brand_id = watch('vehicle_brand_id')
  const vehicle_model_id = watch('vehicle_model_id')
  const [vehicleType, setVehicleType] = useState<any>({})

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', '/vehicles/create-vehicle', { data: vars }), {
    onSuccess: (): void => {
      push('/vehicles')

      onSuccess()
    },
  })
  const { mutate: getModelDetails } = useMutation(() => fetch('GET', `/vehicle-model/${vehicle_model_id}`), {
    onSuccess: (data: any): void => {
      setVehicleType(data?.vehicleType)
    },
  })

  useEffect(() => {
    vehicle_brand_id?.length <= 0 && setValue('vehicle_model_id', [])
  }, [vehicle_brand_id?.length])

  useEffect(() => {
    vehicle_model_id?.length > 0 ? getModelDetails() : setVehicleType('')
  }, [vehicle_model_id?.length])

  function removeEmptyKeys(obj: any) {
    return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== ''))
  }

  const addVehicle = async (formData: any) => {
    userType === USER_TYPE.ADMIN && (formData.corporateId = +formData?.corporateId[0])
    formData.starting_balance_fuel = +formData?.starting_balance_fuel
    formData.fuel_consumption_benchmark = +formData?.fuel_consumption_benchmark
    formData.replenish_amount = +formData?.replenish_amount
    formData.max_fuel_liters = +formData?.max_fuel_liters
    formData.vehicle_brand_id = formData?.vehicle_brand_id[0]
    formData.vehicle_model_id = formData?.vehicle_model_id[0]
    formData.year = +formData?.year
    formData.vehicle_type_id = +vehicleType?.id
    const payload = removeEmptyKeys(formData)
    mutate(payload)
  }

  return (
    <Container>
      <Flex ref={headerRef} flexDir='column' className='margin-150'>
        <Header
          title={dictionary().ADD_NEW_VEHICLE}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && addVehicle(getValues() as any)} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(addVehicle as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            {userType === USER_TYPE.ADMIN && (
              <AutoCompleteControl
                control={control}
                errors={errors}
                identifier='corporateId'
                label={dictionary().CORPORATE}
                route='/corporates'
                displayFormatter={(option: any) => option?.name}
                width={['100%', '40%', '40%', '40%']}
                dataTest={dictionary().CORPORATE}
              />
            )}
            <FormControl
              id={dictionary().NUMBER_PLATE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={
                !!errors.number_plate ||
                (error as any)?.data?.message === 'this plate number is already exist' ||
                (error as any)?.data?.message === ' plate number already exists'
              }
            >
              <FormLabel>
                {dictionary().NUMBER_PLATE} <Required />
              </FormLabel>
              <Input
                type='string'
                data-test={dictionary().NUMBER_PLATE}
                {...register('number_plate', {
                  required: dictionary().REQUIRED,
                  pattern: {
                    value: /^[a-zA-Z0-9\u0600-\u06FF]+[a-zA-Z0-9\u0600-\u06FF ]*$/,
                    message: dictionary().ADD_VALID_PLATE_NUMBER,
                  },
                  validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                })}
              />
              <FormErrorMessage>{errors?.number_plate?.message}</FormErrorMessage>
              <FormErrorMessage>
                {((error as any)?.data?.message === 'this plate number is already exist' || (error as any)?.data?.message === ' plate number already exists') &&
                  dictionary().THIS_PLATE_NUMBER_IS_ALREADY_OCCURED}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              id={dictionary().CAR_BRAND}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.vehicle_brand_id}
            >
              <FormLabel>
                {dictionary().CAR_BRAND} <Required />
              </FormLabel>
              <Controller
                name={`vehicle_brand_id`}
                rules={{ required: dictionary().REQUIRED }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    isInvalid={!!errors?.vehicle_brand_id}
                    onChange={onChange}
                    value={value}
                    dataTest={dictionary().CAR_BRAND}
                    searchRoute='/vehicle-brand'
                    singleRoute='/vehicle-brand'
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.brand_name}
                  />
                )}
              />
              <FormErrorMessage>{errors?.vehicle_brand_id?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().CAR_MODEL}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.vehicle_model_id}
            >
              <FormLabel>
                {dictionary().CAR_MODEL} <Required />
              </FormLabel>
              <Controller
                name={`vehicle_model_id`}
                rules={{ required: dictionary().REQUIRED }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    isInvalid={!!errors?.vehicle_model_id}
                    onChange={onChange}
                    value={value}
                    dataTest={dictionary().CAR_MODEL}
                    searchRoute={`/vehicle-model?vehicle_brand_id=${vehicle_brand_id}`}
                    singleRoute={`/vehicle-model`}
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.name}
                    disabled={!vehicle_brand_id?.length}
                    enableAutoDelete={true}
                  />
                )}
              />
              <FormErrorMessage>{errors?.vehicle_model_id?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().VEHICLE_TYPE} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().VEHICLE_TYPE}</FormLabel>
              <Input data-test={dictionary().VEHICLE_TYPE} disabled value={vehicleType?.name?.ar} />
            </FormControl>

            <FormControl id={dictionary().MAX_LITERS} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} isInvalid={!!errors.max_fuel_liters}>
              <FormLabel>
                {dictionary().MAX_LITERS} <Required />
              </FormLabel>
              <Input
                placeholder='0'
                type='number'
                data-test={dictionary().MAX_LITERS}
                {...register('max_fuel_liters', {
                  required: dictionary().REQUIRED,
                  pattern: {
                    value: /^[1-9]\d*$/,
                    message: dictionary().THIS_INPUT_ACCEPTED_ONLY_NUMBERS,
                  },
                  min: {
                    value: 0,
                    message: dictionary().THIS_INPUT_CANT_BE_BELOW_ZERO,
                  },
                })}
              />
              <FormErrorMessage>{errors.max_fuel_liters?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().FUEL_CONSUMPTION_VALUE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.fuel_consumption_benchmark}
            >
              <FormLabel>{dictionary().FUEL_CONSUMPTION_VALUE}</FormLabel>
              <Input
                placeholder='0'
                type='number'
                data-test={dictionary().FUEL_CONSUMPTION_VALUE}
                {...register('fuel_consumption_benchmark', {
                  required: false,
                  min: {
                    value: 0,
                    message: dictionary().THIS_INPUT_CANT_BE_BELOW_ZERO,
                  },
                })}
              />
              <FormErrorMessage>{errors.fuel_consumption_benchmark?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              id={dictionary().OPENING_BALANCE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.starting_balance_fuel}
            >
              <FormLabel>{dictionary().OPENING_BALANCE}</FormLabel>
              <Input
                data-test={dictionary().OPENING_BALANCE}
                placeholder='0'
                type='number'
                {...register('starting_balance_fuel', {
                  required: false,
                  min: {
                    value: 0,
                    message: dictionary().THIS_INPUT_CANT_BE_BELOW_ZERO,
                  },
                })}
              />
              <FormErrorMessage>{errors.starting_balance_fuel?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().REPLENISHMENT_AMOUNT}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.replenish_amount}
            >
              <FormLabel>{dictionary().REPLENISHMENT_AMOUNT}</FormLabel>
              <Input
                placeholder='0'
                data-test={dictionary().REPLENISHMENT_AMOUNT}
                type='number'
                {...register('replenish_amount', {
                  required: false,
                  pattern: {
                    value: /^\d+$/,
                    message: dictionary().THIS_INPUT_ACCEPTED_ONLY_INTEGER_NUMBERS,
                  },
                  min: {
                    value: 0,
                    message: dictionary().THIS_INPUT_CANT_BE_BELOW_ZERO,
                  },
                  validate: (value) => {
                    if (value > +stratingBalance) return dictionary().REPLENISH_AMOUNT_LESS_THAN_STARTING_BALANCE
                  },
                })}
              />
              <FormErrorMessage>{errors.replenish_amount?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id={dictionary().FUEL_TYPES} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} isInvalid={!!errors.fuelTypeIds}>
              <FormLabel>
                {dictionary().FUEL_TYPES} <Required />{' '}
              </FormLabel>
              <Controller
                name={`fuelTypeIds`}
                control={control}
                rules={{ required: dictionary().REQUIRED }}
                render={({ field: { onChange } }) => (
                  <AutoCompleteInput
                    dataTest={dictionary().FUEL_TYPES}
                    onChange={onChange}
                    searchRoute='/fuel-types'
                    singleRoute='/fuel-types'
                    multiple={true}
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.name?.ar}
                    isInvalid={!!errors?.fuelTypeIds}
                  />
                )}
              />
              {errors.fuelTypeIds?.message && <p style={{ color: '#e53e3e', paddingTop: '5px' }}>{errors.fuelTypeIds?.message}</p>}
            </FormControl>
            <FormControl
              id={dictionary().CODE_FOR_VEHICLE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.code || (error as any)?.data?.message === 'code already exists'}
            >
              <FormLabel>{dictionary().CODE_FOR_VEHICLE}</FormLabel>
              <Input
                data-test={dictionary().CODE_FOR_VEHICLE}
                type='string'
                {...register('code', {
                  required: false,
                })}
              />
              <FormErrorMessage>{errors?.code?.message}</FormErrorMessage>
              <FormErrorMessage>{(error as any)?.data?.message === 'code already exists' && dictionary().THIS_CODE_IS_ALREADY_OCCURED}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().MANUFACTURE_YEAR} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} isInvalid={!!errors.year}>
              <FormLabel>
                {dictionary().MANUFACTURE_YEAR} <Required />
              </FormLabel>
              <Input
                placeholder='2010'
                type='number'
                data-test={dictionary().MANUFACTURE_YEAR}
                {...register('year', {
                  required: dictionary().REQUIRED,
                  pattern: {
                    value: /^(19[0-9]{2}|20[0-9]{2}|2100)$/,
                    message: dictionary().ADD_VALID_YEAR,
                  },
                })}
              />
              <FormErrorMessage>{errors.year?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddVehicle
