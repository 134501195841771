import { Box, Grid, GridItem } from '@chakra-ui/react'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { CASHING_KEY, USER_TYPE } from '../../../constants'
import dictionary from '../../../dictionary'
import { SearchByNumber } from '../../../pages/tax-ids/SearchByNumber'
import CorporateDropList from '../CorporateDropList'
import CorporateDropListEInvoices from '../CorporateDropListEInvoices'
import DuplicateFilterModel from '../duplicate-transactions/duplicateFilters'
import MultipeCustomerDropdown from '../MultipeCustomerDropdown'
import MultipeSerialNumberDropdown from '../MultipeSerialNumberDropdown'
import MultipeStationDropdown from '../MultipeStationDropdown'
import { Search } from '../Search'
import SearchById from '../SearchById'
import SearchDate from '../SearchDate'
import StationDropList from '../StationDropList'
import StationProviderDropList from '../StationProviderDropList'
import ViewTags from '../ViewTags'

interface FiltersProps {
  cashingKey: string
  userType: string | null
  tags: any
  query: any
  hasSearchDate: boolean | undefined
  setSelectedStation: React.Dispatch<React.SetStateAction<null>>
  setSelectedCorporate: React.Dispatch<React.SetStateAction<null>>
  setSelectedStationProvider: React.Dispatch<React.SetStateAction<null>>
  hasStationDropListFilter: boolean
  corporateDropListPlaceholder: string
}

const Filters: React.FC<FiltersProps> = ({
  cashingKey,
  userType,
  tags,
  query,
  hasSearchDate,
  setSelectedCorporate,
  setSelectedStation,
  setSelectedStationProvider,
  hasStationDropListFilter,
  corporateDropListPlaceholder
}) => {
  const { pathname } = useLocation()
  return (
    <>
      {userType === USER_TYPE.CORPORATE_STAFF && (
        <Grid templateColumns='repeat(12, 1fr)' mt={['0', '0', '10px', '10px']}>
          <GridItem colSpan={[6, 6, 3, 3]}>
            <StationDropList setSelectedStation={setSelectedStation} />
          </GridItem>
        </Grid>
      )}
      {cashingKey === CASHING_KEY.GET_TAX_IDS && (
        <Grid templateColumns='repeat(12, 1fr)' gap={6} mt={['0', '0', '10px', '10px']}>
          <GridItem colSpan={[6, 6, 3, 3]}>
            <MultipeCustomerDropdown />
          </GridItem>
          <GridItem colSpan={[6, 6, 3, 3]} display='flex' alignItems='end'>
            <SearchByNumber />
          </GridItem>
        </Grid>
      )}
      {cashingKey === CASHING_KEY.GET_POS_DEVICES && (
        <Grid templateColumns='repeat(12, 1fr)' gap={6} mt={['0', '0', '10px', '10px']}>
          <GridItem colSpan={[6, 6, 3, 3]}>
            <MultipeStationDropdown />
          </GridItem>
          <GridItem colSpan={[6, 6, 3, 3]}>
            <MultipeSerialNumberDropdown />
          </GridItem>
        </Grid>
      )}
      {cashingKey === CASHING_KEY.GET_DUPLICTE_TRANSACTIONS && (
        <Grid templateColumns='repeat(12, 1fr)' gap={6} display={['block', 'block', 'flex']} mt={['0', '0', '10px', '10px']}>
          <GridItem colSpan={[6, 6, 1]} display='flex' alignItems='center'>
            {tags && (
              <Box mt={['10px ', '10px', '25px']} mb={['10px ', '10px', '0']}>
                {' '}
                <ViewTags tags={tags} />
              </Box>
            )}
          </GridItem>
          <GridItem colSpan={[12, 12, 5, 5]} display='flex' alignItems='center'>
            <SearchDate />
          </GridItem>
          <GridItem colSpan={[12, 12, 3, 3]} display='flex' alignItems='center'>
            <DuplicateFilterModel />
          </GridItem>
        </Grid>
      )}
      {cashingKey === CASHING_KEY.TRANSACTIONS_REVIEWED && (
        <Grid templateColumns='repeat(12, 1fr)' gap={6} display={['block', 'block', 'flex']} mt={['0', '0', '10px', '10px']}>
          <GridItem colSpan={[6, 6, 1]} display='flex' alignItems='center'>
            {tags && (
              <Box mt={['10px ', '10px', '25px']} mb={['10px ', '10px', '0']}>
                {' '}
                <ViewTags tags={tags} />
              </Box>
            )}
          </GridItem>

          <GridItem colSpan={[12, 12, 5, 5]} display='flex' alignItems='center'>
            {hasSearchDate && <SearchDate />}
          </GridItem>
        </Grid>
      )}
      {cashingKey === CASHING_KEY.GET_STATIONS ? (
        <Grid templateColumns='repeat(12, 1fr)' gap={6} display={['block', 'block', 'flex']} mt={['0', '0', '10px', '10px']}>
          <GridItem colSpan={[6, 6, 1]} display='flex' alignItems='center'>
            {tags && (
              <Box mt={['10px ', '10px', '25px']} mb={['10px ', '10px', '0']}>
                {' '}
                <ViewTags tags={tags} />
              </Box>
            )}
          </GridItem>
          <StationProviderDropList setSelectedStationProvider={setSelectedStationProvider} />
        </Grid>
      ) : (cashingKey === CASHING_KEY.GET_FUEL_TRANSACTIONS ||
          cashingKey === CASHING_KEY.GET_CORPORATES ||
          cashingKey === CASHING_KEY.GET_DRIVERS ||
          cashingKey === CASHING_KEY.GET_STATIONS ||
          cashingKey === CASHING_KEY.GET_STATION_PROVIDERS ||
          cashingKey === CASHING_KEY.GET_VEHICLE ||
          cashingKey === CASHING_KEY.E_INVOICES ||
          cashingKey === CASHING_KEY.GET_STORE_TRANSACTIONS ||
          cashingKey === CASHING_KEY.GET_FUEL_TRANSACTIONS_FOR_DATA_ENTRY ||
          cashingKey === CASHING_KEY.GET_CORPORATES_ACCOUNTS ||
          cashingKey === CASHING_KEY.GET_STATIONS_ACCOUNTS) &&
        userType !== USER_TYPE.STATION_STAFF &&
        userType !== USER_TYPE.CORPORATE_STAFF ? (
        <Grid templateColumns='repeat(12, 1fr)' gap={6} display={['block', 'block', 'flex']} mt={['0', '0', '10px', '10px']}>
          <GridItem colSpan={[6, 6, 1]} display='flex' alignItems='center'>
            {tags && (
              <Box mt={['10px ', '10px', '25px']} mb={['10px ', '10px', '0']}>
                {' '}
                <ViewTags tags={tags} />
              </Box>
            )}
          </GridItem>

          <GridItem colSpan={[12, 12, 5, 5]} display='flex' alignItems='center'>
            {hasSearchDate && <SearchDate />}
          </GridItem>

          { hasStationDropListFilter && (
            <GridItem colSpan={[6, 6, 3, 3]}>
            {cashingKey !== CASHING_KEY.GET_STATIONS_ACCOUNTS &&
              cashingKey !== CASHING_KEY.GET_CORPORATES_ACCOUNTS &&
              userType !== USER_TYPE.STATION_STAFF &&
              cashingKey !== CASHING_KEY.E_INVOICES && <StationDropList setSelectedStation={setSelectedStation} />}
            </GridItem>
          )}

          <GridItem colSpan={[6, 6, 3, 3]}>
            {cashingKey !== CASHING_KEY.GET_STATIONS_ACCOUNTS &&
              cashingKey !== CASHING_KEY.GET_CORPORATES_ACCOUNTS &&
              userType !== USER_TYPE.STATION_STAFF &&
              cashingKey !== CASHING_KEY.E_INVOICES && <CorporateDropList setSelectedCorporate={setSelectedCorporate} corporateDropListPlaceholder={corporateDropListPlaceholder} />}
          </GridItem>
          {cashingKey === CASHING_KEY.E_INVOICES && (
            <GridItem colSpan={[6, 6, 3, 3]}>
              <CorporateDropListEInvoices />
            </GridItem>
          )}
          {pathname === '/fuel-transactions' && (
            <GridItem colSpan={[6, 6, 3, 3]}>
              <SearchById />
            </GridItem>
          )}
          {/* {cashingKey === CASHING_KEY.TRANSACTIONS_REVIEWED && (
            <GridItem colSpan={[6, 6, 3, 3]}>
              <SearchDifferenceRatio />
            </GridItem>
          )} */}
        </Grid>
      ) : cashingKey === CASHING_KEY.GET_VEHICLE && userType !== USER_TYPE.CORPORATE_STAFF ? (
        <CorporateDropList setSelectedCorporate={setSelectedCorporate} corporateDropListPlaceholder={corporateDropListPlaceholder} />
      ) : cashingKey === CASHING_KEY.GET_CONFITMED_PAYMENTS ? (
        <Box width={'25%'}>
          <CorporateDropList setSelectedCorporate={setSelectedCorporate} corporateDropListPlaceholder={corporateDropListPlaceholder}/>
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}

export default Filters
